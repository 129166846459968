.nav{
  /* display:flex; */
  position: fixed;
  top: 0px;
  /* justify-content: space-around; */
  /* align-items: center; */
  min-height: 5vh;
  color: white;
  font-size: 45px;
  text-shadow: 1px 1px 10px grey;
  margin: 0;
  padding-bottom: 10px;
  background-color: rgb(179, 178, 178);
  width: 100%;
  text-align: center;
  justify-content: center;

}

.text {
  margin-left: 1vw;
  font-size: 27px;
}

.icon {
  width: 50px;
  height: 50px;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 20%;
}

.pageLinks {
  margin-top: 0.5vh;
  float:left;
  display: inline-block;
  text-align: center;
}

.name {
  float:left;
  display: inline-block;
  text-align: center;
}

.iconLinks {
  float:middle;
}

.active {
  color: white;
  text-decoration: none;
}

.link {
  text-decoration: none;
  color:rgb(172, 171, 171);
}

.websiteButton {
  font-size: 40px;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 7px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  color:white;
  border: none;
}

.websiteButton:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
}

.linkedInButton {
  font-size: 40px;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 7px;
  text-align: center;
  border: none;
  background-color: rgb(49, 119, 250);
  color: white;
}

.linkedInButton:hover {
  cursor: pointer;
  background-color: rgb(109, 160, 255);
}

.githubButton {
  font-size: 40px;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 7px;
  text-align: center;
  border: none;
  background-color: rgb(49, 119, 250);/* rgba(0, 0, 0, 0.85); */
  color: white;
}

.githubButton:hover {
  cursor: pointer;
  background-color: rgba(109, 160, 255);/* rgba(0, 0, 0, 0.6); */
}