.newCommentBox {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
}

.commentInput {
  border-radius: 15px;
  border-style: solid;
  border-color: grey;
  padding: 5px;
  width: 40rem;
}

.commentText {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  width: 40rem;
}

.postCommentButton {
  display: flex;
  background-color: rgb(10, 128, 238);
  border: none;
  color: white;
  padding: 6px 12.5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 25px;
  margin-left: 5.5rem;
}

.postCommentButton:hover {
  cursor: pointer;
  background-color: rgb(5, 95, 180);
}